@import 'https://fonts.googleapis.com/css?family=Orbitron';
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600;700&family=Barlow:wght@300;400;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

@font-face {
  font-family: 'Digital Display';
  src: url(./styles/fonts/digital\ display\ tfb.ttf) format('truetype');
  /* src: url(./styles/fonts/Digital\ Display.ttf) format('truetype'); */
}

@font-face {
  font-family: 'Race Sport';
  src: url(./styles/fonts/RaceSport.ttf) format('truetype');
}

@font-face {
  font-family: 'Segoe UI';
  src: url(./styles/fonts/Segoe\ UI.ttf) format('truetype');
}

@font-face {
  font-family: 'Segoe UI Bold';
  src: url(./styles/fonts/Segoe\ UI\ Bold.ttf) format('truetype');
}

body {
  font-family: 'Inter', sans-serif;
  overflow: hidden;
}

/* sign-in component styling */
:root {
  --amplify-font-family: 'Inter', sans-serif;
  --amplify-primary-color: var(--bs-blue);
  --amplify-primary-tint: var(--bs-blue);
  --amplify-primary-shade: var(--bs-blue);
  --app-height: 100%;
  color-scheme: normal;
  --bs-font-sans-serif: 'Inter', sans-serif;
}

@media screen and (orientation: portrait) {
  .message {
    position: absolute;
    top: 50%;
    left: 25%;
    visibility: visible;
  }
  
  body * {
    visibility: hidden;
  }
}

@media screen and (orientation: landscape) {
  .message {
    display: none;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  touch-action: none;
}

html {
  overflow: hidden;
  position: fixed;
}

body {
  overflow: auto;
  position: relative;
}

body {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

input:read-only[type='text'] {
  background-color: white !important;
}

input:disabled[type='text'], 
input:disabled[type='date'],
.form-control:disabled,
.form-select:disabled {
  background-color: #e9ecef !important;
  color: #808080 !important;
}

.form-floating > .form-control {
  padding-right: 38px;
}

.form-control.is-invalid {
  background-position: right calc(0.375em + 1.588rem) center !important;
}

input.async-input-select-focused.form-control:focus::-webkit-input-placeholder, 
input.async-input-select-blurred.form-control::-webkit-input-placeholder {
  color: #C0C0C0 !important;
}

input[type='date'],
input[type='datetime-local'] {
  text-align: left !important;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

[type='date']:before {
  color: #aaa;
  content: attr(placeholder);
}

[type='datetime-local']:before {
  color: #aaa;
  content: attr(placeholder);
}

.label-text > label {
  color: #808080 !important;
}

.react-tagsinput {
  border: 0 !important;
  padding-top: 2px !important;
}

.game-user-roles-tagsinput {
  background-color: #f5f5f5;
}

.user-role-react-tagsinput-tag {
  border-radius: 5px;
  background-color: #d8e6ff;
  color: #616161;
  border: 1px solid #d8e6ff;
  display: inline-block;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  display: inline-flex;
}

.user-role-acitve-background-color {
  background-color: #d8e6ff;
}

.user-role-inactive-background-color {
  background-color: #b9b9b9;
}

a.user-role-react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  margin-left: 5px; 
  text-decoration: none;
}

a.user-role-react-tagsinput-remove::before {
  content: ' ×';
}

.user-active-tag-input {
  border: 0 !important;
  padding-top: 2px !important;
  padding-left: 5px;
  background-color: #e9ecef;
}

select.enabled {
  background-color: transparent;
}

select.disabled {
  background-color: #d5d5d5 !important;
  background-image: none !important;
}

.tooltip {
  width: auto !important;
}

.home-tooltip {
  border: 1px solid #979797 !important;
}

.list-items-scroll::-webkit-scrollbar,
.rbt-menu::-webkit-scrollbar {
  width: 4px;
  /* background-color: #f5f5f535; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.list-items-scroll::-webkit-scrollbar-thumb,
.rbt-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b4b4b4 !important;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}
.list-items-scroll::-webkit-scrollbar-track,
.rbt-menu::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* border-radius: 10px; */
}

.ipad-page-list-Items::-webkit-scrollbar {
  width: 4px !important;
  background-color: transparent !important;
}
.ipad-page-list-Items::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.ipad-page-list-Items-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  height: 30px !important;
  background: rgba(55, 44, 44, 0.226) !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
}

.home-page-calendar {
  height: 100%;
  border: 0 !important;
  flex-direction: column;
  justify-content: center;
}

.home-page-calendar-ipad {
  width: 100% !important;
  max-width: 30vw !important;
}

.home-page-calendar-desktop {
  width: 100% !important;
  max-width: 23vw !important;
}

.home-page-calendar .react-calendar__year-view, 
.home-page-calendar .react-calendar__decade-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home-page-calendar button.react-calendar__month-view__days__day {
  border-radius: 50% !important;
  color: #000000;
  margin-left: 0.5vw !important;
  font-size: 1vw !important;
}

.home-page-calendar div.react-calendar__month-view__weekdays__weekday {
  padding: 0 !important;
  max-width: fit-content;
  font-size: 1vw !important;
}

.home-page-calendar button.react-calendar__year-view__months__month {
  height: 48% !important;
  padding: 10px !important;
  font-size: 1vw !important;
}

.home-page-calendar button.react-calendar__decade-view__years__year {
  padding-top: 10px !important;
  font-size: 1vw !important;
}

.home-page-calendar button.react-calendar__century-view__decades__decade {
  padding: 10px !important;
  font-size: 1vw !important;
}

.home-page-calendar .react-calendar__calendar-month-view-ipad, 
.home-page-calendar .react-calendar__calendar-year-view-ipad, 
.home-page-calendar .react-calendar__calendar-decade-view-ipad {
  max-width: 31vw !important;
}

.home-page-calendar .react-calendar__calendar-month-view-desktop, 
.home-page-calendar .react-calendar__calendar-year-view-desktop, 
.home-page-calendar .react-calendar__calendar-decade-view-desktop {
  max-width: 23vw !important;
}

.home-page-calendar .react-calendar__month-view__days,
.home-page-calendar .react-calendar__month-view__weekdays,
.home-page-calendar .react-calendar__year-view__months,
.home-page-calendar .react-calendar__decade-view__years,
.home-page-calendar .react-calendar__century-view__decades {
  justify-content: space-around;
}

.home-page-calendar .react-calendar__tile--hasActive {
  background-color: transparent !important;
}

.home-page-calendar button.react-calendar__year-view__months__month > abbr {
  display: none;
} 

.home-page-calendar button.home-page-react-calendar-ipad {
  max-width: 3.5vw !important;
  max-height: 3.5vw !important;
}

.home-page-calendar button.home-page-react-calendar-desktop {
  max-width: 2.5vw !important;
  max-height: 2.5vw !important;
  padding-top: 0.6vw !important;
}

.home-page-calendar button.home-page-react-calendar-month-ipad {
  margin-left: 1vw !important;
  max-width: 3.5vw !important;
}

.home-page-calendar button.home-page-react-calendar-month-desktop {
  margin-left: 0.5vw !important;
  max-width: 3vw !important;
}

.home-page-calendar button.home-page-react-calendar-year-ipad {
  max-width: 5.5vw !important;
  max-height: 3vw !important;
}

.home-page-calendar button.home-page-react-calendar-year-desktop {
  max-width: 4.5vw !important;
  max-height: 3vw !important;
}

.home-page-calendar button.react-calendar__tile--active {
  color: #ffffff !important;
}

.home-page-calendar button.react-calendar__tile--selectedDate {
  background-color: transparent !important;
  color: #000000 !important;
}

.home-page-calendar button.react-calendar__tile--today {
  background-color: #008d18 !important;
}
.home-page-calendar button.react-calendar--tile--upcoming {
  background-color: #009fee !important;
}
.home-page-calendar button.react-calendar__tile--past {
  background-color: #535353 !important;
}
.home-page-calendar button.react-calendar__tile--nogames {
  background-color: #c1212f !important;
}

.csv-upload-file input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.fade-In-fade-out {
  -webkit-animation: fadeInfadeOut 5s linear 1 forwards;
  animation: fadeInfadeOut 5s linear 1 forwards;
}

@-webkit-keyframes fadeInfadeOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeInfadeOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.progress-bar {
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.progress{
  border-radius: 40px !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}