// somewhere you import bootstrap and react-bootstrap-typeahead
@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-typeahead/css/Typeahead';

.rbt-aux {
  max-height: 65px;
  & .rbt-close {
    margin-top: unset;
    @extend .btn-close; // inherit all the properties of the bootstrap class `.btn-close`
    pointer-events: auto; /* Override pointer-events: none; above */
    span {
      display: none; // hide the BS4 compatible span element
    }
  }
}

.rbt-menu > .dropdown-item:active {
  background-color: #F3FCFF;
}
